<template>
    <v-card color="#303030" class="pt-10 pb-10 pr-10 pl-10">
        <v-card-text>
            <v-form ref="form" v-model="formValid">
                <v-row>
                    <v-col>
                        <v-text-field
                            label="Name"
                            v-model="inputs.name"
                            :rules="[rules.required]"
                            outlined
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="10">
                        <v-file-input
                            @change="onFileChanged('file', $event)"
                            label="Upload Locations(xls | xlsx | csv)"
                            outlined
                            placeholder="Select your file"
                            :rules="[rules.file]"
                            accept=".xls, .xlsx, .csv"
                        ></v-file-input>
                    </v-col>
                    <v-col cols="2">
                        <v-btn
                            @click="downloadSample"
                            class="ma-3"
                            outlined small
                            color="white"
                        ><v-icon left>fa-download</v-icon> Sample</v-btn>
                    </v-col>
                </v-row>
                <v-row align="center" justify="end">
                    <v-col>
                        <v-btn
                            color="primary"
                            @click="save"
                            :loading="isSaving"
                        >Save</v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
    </v-card>
</template>

<script>
    import _ from 'lodash';
    import Constants from '@/constants';

    export default {

        name: 'CustomUploads',

        props: ['id', 'data'],

        data: () => ({
            formValid: true,
            inputs: {
                name: '',
                file: ''
            },
            rules: {
                required: value => !!value || 'Required',
                file: value => typeof value !== 'undefined' || 'File is required'
            },
            isSaving: false
        }),

        methods: {

            onFileChanged(control, evt) {
                if (typeof evt === 'undefined') {
                    this.inputs[control] = '';
                } else {
                    this.inputs[control] = evt;
                }
            },

            downloadSample() {
                const url = Constants.APP_URL + '/samples/custom-posts_sample.xlsx';
                window.open(url, '_blank');
            },

            save() {
                this.isSaving = true;
                this.$refs.form.validate();

                if (this.formValid) {
                    const inputs = _.cloneDeep(this.inputs);
                    const data = new FormData();
                    data.append('name', inputs.name);

                    if (typeof inputs.file === 'object') {
                        data.append('file', inputs.file);
                    }

                    this.$store.dispatch('socialmosaic/saveCustomPosts', {
                        socialMosaicId: this.id,
                        customPosts: data
                    }).then(() => {
                        this.isSaving = false;
                        this.$router.replace({
                            name: 'socialmosaic',
                            params: {
                                id: this.id,
                                selectedTab: 'feed-pending'
                            }
                        });
                    }).catch(() => {});
                } else {
                    this.isSaving = false;
                }
            }
        }
    }
</script>

<style scoped lang="scss">
</style>
