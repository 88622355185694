<template>
    <div>
        <v-card-title class="headline">
            Languages
        </v-card-title>

        <v-card-text>
            <v-progress-circular
                v-if="loading === 'loading'"
                indeterminate
                color="primary"
            ></v-progress-circular>
            <v-simple-table v-if="loading === 'loaded'">
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-center">LANGUAGE</th>
                            <th class="text-center">ACTIONS</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="tempLanguage in tempLanguages" :key="tempLanguage.language">
                            <td class="text-center">
                                {{ tempLanguage.language }}
                            </td>
                            <td class="text-center">
                                <v-btn
                                    @click.native="destroy(tempLanguage.id)"
                                    icon="icon"
                                ><v-icon class="text-info">fa-trash-alt</v-icon></v-btn>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>

            <v-form ref="form" v-model="formValid">
                <v-row align="center" justify="space-between">
                    <v-col>
                        <v-select
                            :items="langs"
                            item-text="language"
                            item-value="id"
                            v-model="language.language_id"
                            label="Language"
                            single-line
                            bottom
                            :rules="[rules.required]"
                        ></v-select>
                    </v-col>
                    <v-col>
                        <v-btn
                            color="primary"
                            @click="add"
                            :loading="isSaving"
                        >Add</v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
    </div>
</template>

<script>
    import _ from 'lodash';

    export default {

        name: 'Languages',

        props: ['id'],

        computed: {

            langs() {

                return this.$store.getters['socialmosaic/langs'];
            },

            languages() {

                return this.$store.getters['socialmosaic/languages'];
            },

            loading() {

                return this.$store.getters['socialmosaic/languagesStatus'];
            }
        },

        data: () => ({
            formValid: true,
            language: {
                language_id: 1
            },
            rules: {
                required: value => !!value || 'Required'
            },
            tempLanguages: [],
            isSaving: false
        }),

        updated() {
            this.tempLanguages = _.cloneDeep(this.languages);
        },

        methods: {

            destroy(languageId) {
                this.$store.dispatch('socialmosaic/deleteLanguage', {
                    socialMosaicId: this.id,
                    languageId
                }).then(() => {
                    this.$store.dispatch('socialmosaic/getLanguages', this.id).then(() => {
                        this.tempLanguages = _.cloneDeep(this.languages);
                    }).catch(() => {});
                }).catch(() => {});
            },

            add() {
                this.$refs.form.validate();

                if (this.formValid) {
                    this.isSaving = true;
                    const language = _.cloneDeep(this.language);

                    this.$store.dispatch('socialmosaic/newLanguage', {
                        socialMosaicId: this.id,
                        language
                    }).then(() => {
                        this.$store.dispatch('socialmosaic/getLanguages', this.id).then(() => {
                            this.tempLanguages = _.cloneDeep(this.languages);
                            this.isSaving = false;
                        }).catch(() => {});
                    }).catch(() => {});
                }
            }
        }
    }
</script>
