<template>
    <v-card color="#303030" class="pt-10 pb-10 pr-10 pl-10">
        <v-card-actions>
            <v-row >
                <v-col>
                    <v-btn
                        outlined
                        @click.native="approve()"
                        :loading="isApproving"
                    ><v-icon left>fa-thumbs-up</v-icon> APPROVE</v-btn>
                </v-col>
                <v-col>
                    <v-btn
                        outlined
                        @click.native="reject()"
                        :loading="isRejecting"
                    ><v-icon left>fa-thumbs-down</v-icon> REJECT</v-btn>
                </v-col>
                <v-col>
                    <v-text-field
                        v-model="keywords"
                        @keyup="() => {}"
                        label="Search"
                        append-icon="fa-search"
                        outlined dense
                    ></v-text-field>
                </v-col>
                <v-col>
                    <v-btn
                        outlined
                        @click.native="refresh()"
                        :loading="isRefreshing"
                    ><v-icon left>fa-sync</v-icon> REFRESH</v-btn>
                </v-col>
            </v-row>
        </v-card-actions>

        <v-card-text>
            <v-data-table
                v-bind:headers="headers"
                v-bind:items="posts"
                v-model="selected"
                show-select
                :items-per-page="paginator.per_page"
                hide-default-footer
                item-key="id"
                :loading="loading === 'loading'"
                :page.sync="currentPage"
                class="elevation-1"
            >
                <template v-slot:body="{ items }">
                    <tbody>
                    <tr
                        class="table__item"
                        v-for="item in items"
                        :key="item.id"
                    >
                        <td>
                            <v-checkbox
                                hide-details
                                v-model="selected"
                                :value="item.id"
                            ></v-checkbox>
                        </td>
                        <td>
                            {{ item.user }}<br><br>
                            <v-btn
                                @click.native="blockUser(item)"
                                outlined small
                            >
                                <v-icon>fa-ban</v-icon>
                                BLOCK USER
                            </v-btn>
                        </td>
                        <td>
                            {{ item.post }}<br>
                            {{ item.filter }}
                        </td>
                        <td>
                            Time: {{ item.time }}<br>
                            Via: {{ item.source }}
                        </td>
                        <td>
                            <v-dialog v-model="item.dialog" class="media-image__dialog__content">
                                <template #activator="{ on: openModal }">
                                    <v-img
                                        v-if="item.image !== '' && item.image !== null"
                                        slot="activator"
                                        :src="item.image"
                                        v-on="{...openModal}"
                                        height="100"
                                        width="100"
                                    ></v-img>
                                </template>
                                <v-card
                                    class="max-auto"
                                    tile
                                >
                                    <v-card-title
                                        class="headline indigo lighten-1"
                                        primary-title
                                    >Media Image</v-card-title>
                                    <v-row
                                        align="end"
                                        class="fill-height"
                                    >
                                        <v-col
                                            align-self="center"
                                            justify="center"
                                        >
                                            <v-img
                                                :src="item.image"
                                                contain
                                                max-height="600"
                                            ></v-img>
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-dialog>
                        </td>
                    </tr>
                    </tbody>
                </template>
            </v-data-table>

            <v-row>
                <v-col class="text-center pt-2">
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                class="ma-2"
                                tile large icon
                                :disabled="currentPage === 1"
                                @click="prev"
                                v-on="on"
                            ><v-icon>fa-chevron-left</v-icon></v-btn>
                        </template>
                        <span>Previous Page</span>
                    </v-tooltip>

                    <span
                        class="font-weight-black white--text"
                    >{{ currentPage }}</span>

                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                class="ma-2"
                                tile large icon
                                @click="next"
                                :disabled="(currentPage === paginator.total_pages) || disableNext"
                                v-on="on"
                                :loading="loading === 'loading'"
                            ><v-icon>fa-chevron-right</v-icon></v-btn>
                        </template>
                        <span>Next Page</span>
                    </v-tooltip>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
    import _ from 'lodash';

    export default {

        name: 'Pending',

        props: ['id', 'data'],

        data: () => ({
            keywords: '',
            headers: [
                {
                    text: 'USER',
                    left: true,
                    value: 'post',
                    sortable: true
                },
                {
                    text: 'POST',
                    value: 'post',
                    left: true,
                    sortable: false
                },
                {
                    text: 'DETAILS',
                    value: 'post',
                    left: true,
                    sortable: false
                },
                {
                    text: 'MEDIA',
                    value: 'post',
                    left: true,
                    sortable: false
                }
            ],
            selected: [],
            currentPage: 1,
            disableNext: false,
            isApproving: false,
            isRejecting: false,
            isRefreshing: false
        }),

        computed: {

            posts() {
                return this.$store.getters['socialmosaic/posts'];
            },

            loading() {
                return this.$store.getters['socialmosaic/postsStatus'];
            },

            paginator() {
                return this.$store.getters['socialmosaic/paginator'];
            }
        },

        watch: {

            keywords(val) {
                if (val !== '') {
                    this.search(val);
                }
            }
        },

        methods: {

            approve() {
                this.isApproving = true;

                if (this.selected.length === 0) {
                    this.isApproving = false;
                }

                const approved = [];
                _.each(this.selected, postId => {
                    this.$store.dispatch('socialmosaic/executeActionOnPost', {
                        socialMosaicId: this.id,
                        postId,
                        action: 'approve'
                    }).then(() => {
                        approved.push({});
                        if (approved.length === this.selected.length) {
                            this.isApproving = false;
                        }
                    }).catch(() => {});
                });
            },

            reject() {
                this.isRejecting = true;

                if (this.selected.length === 0) {
                    this.isRejecting = false;
                }

                const rejected = [];
                _.each(this.selected, postId => {
                    this.$store.dispatch('socialmosaic/executeActionOnPost', {
                        socialMosaicId: this.id,
                        postId,
                        action: 'reject'
                    }).then(() => {
                        rejected.push({});
                        if (rejected.length === this.selected.length) {
                            this.isRejecting = false;
                        }
                    }).catch(() => {});
                });
            },

            search: _.debounce(function () {
                this.$store.dispatch('socialmosaic/resetPosts').then(() => {
                    this.currentPage = 1;
                    this.disableNext = false;

                    this.$store.dispatch('socialmosaic/getPosts', {
                        socialMosaicId: this.id,
                        action: 'pending',
                        keywords: this.keywords
                    }).catch(() => {});
                }).catch(() => {});
            }, 500),

            refresh() {
                this.isRefreshing = true;

                this.$store.dispatch('socialmosaic/resetPosts').then(() => {
                    this.$store.dispatch('socialmosaic/refreshPosts', this.id).then(() => {
                        this.currentPage = 1;
                        this.keywords = '';
                        this.disableNext = false;

                        this.$store.dispatch('socialmosaic/getPosts', {
                            socialMosaicId: this.id,
                            action: 'pending'
                        }).then(() => {
                            this.isRefreshing = false;
                        }).catch(() => {});
                    }).catch(() => {});
                }).catch(() => {});
            },

            blockUser(item) {
                const blockedUser = {
                    name: item.user,
                    feeds_type_id: item.feeds_type_id
                };

                this.$store.dispatch('socialmosaic/newBlockedUser', {
                    socialMosaicId: this.id,
                    blockedUser
                }).catch(() => {});
            },

            prev() {
                this.currentPage = this.currentPage - 1;
            },

            next() {
                if (this.posts.length > (this.currentPage * this.paginator.per_page)) {
                    this.currentPage++;
                    return;
                }

                this.disableNext = true;
                this.$store.dispatch('socialmosaic/getPosts', {
                    socialMosaicId: this.id,
                    action: 'pending',
                    keywords: this.keywords
                }).then(() => {
                    this.disableNext = false;
                    this.currentPage++;
                }).catch(() => {});
            }
        }
    }
</script>

<style lang="scss" scoped>
    .media-image__dialog__content {
        overflow: hidden;
    }
</style>
