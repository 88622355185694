<template>
    <div class="creative creative--social-mosaic">
        <v-container>
            <v-tabs
                v-model="tab"
                fixed-tabs
                dark icons-and-text
            >
                <v-tab
                    v-for="t in tabs"
                    :key="t.key"
                >
                    {{ t.name }}
                    <v-icon>{{ t.icon }}</v-icon>
                </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
                <v-tab-item :key="'desktop'">
                    <v-card color="#303030" class="pt-10 pb-10 pr-10 pl-10">
                        <v-card-text>
                            <v-card-title>Preview dimensions:</v-card-title>
                            <v-text-field
                                label="Width"
                                v-model="size.width"
                                data-vv-name="Width"
                            ></v-text-field>
                            <v-text-field
                                label="Height"
                                v-model="size.height"
                                data-vv-name="Height"
                            ></v-text-field>
                        </v-card-text>
                    </v-card>
                </v-tab-item>

                <v-tab-item :key="'tablet'">
                    <v-card color="#303030" class="pt-10 pb-10 pr-10 pl-10">
                        <v-card-text>
                            <v-select
                                label="Select a Device"
                                @input="sizeSelected(tabletSelected, tabletDevices)"
                                v-model="tabletSelected"
                                :items="tabletDevices"
                            ></v-select>
                            <v-spacer></v-spacer>
                            <v-card-title>Preview dimensions:</v-card-title>
                            <v-text-field
                                label="Width"
                                v-model="size.width"
                            ></v-text-field>
                            <v-text-field
                                label="Height"
                                v-model="size.height"
                            ></v-text-field>
                        </v-card-text>
                    </v-card>
                </v-tab-item>

                <v-tab-item :key="'mobile'">
                    <v-card color="#303030" class="pt-10 pb-10 pr-10 pl-10">
                        <v-card-text>
                            <v-select
                                label="Select a Device"
                                @input="sizeSelected(mobileSelected, mobileDevices)"
                                v-model="mobileSelected"
                                :items="mobileDevices"
                            ></v-select>
                            <v-spacer></v-spacer>
                            <v-card-title>Preview dimensions:</v-card-title>
                            <v-text-field
                                label="Width"
                                v-model="size.width"
                            ></v-text-field>
                            <v-text-field
                                label="Height"
                                v-model="size.height"
                            ></v-text-field>
                        </v-card-text>
                    </v-card>
                </v-tab-item>
            </v-tabs-items>

            <v-card class="pt-10 pb-10 pr-10 pl-10">
                <v-card-text class="text-xs-center">
                    <iframe
                        v-if="loading === 'loaded' && posts.length > 0"
                        :src="iframeUrl"
                        :style="{
                        width,
                        height,
                        margin: '0px auto'
                    }"
                        frameborder="0"
                        scrolling="no"
                    ></iframe>

                    <p
                        class="headline grey--text lighten-1--text"
                        v-if="loading === 'loaded' && posts.length <= 0"
                    >
                        This social mosaic does not have any approved posts!
                    </p>

                    <v-progress-circular
                        v-if="loading === 'loading'"
                        indeterminate
                        color="primary"
                    ></v-progress-circular>
                </v-card-text>
            </v-card>
        </v-container>
    </div>
</template>

<script>
    import Constants from '@/constants';
    import _ from 'lodash';

    export default {

        name: 'Preview',

        data: () => ({
            ready: false,
            tab: 'desktop',
            tabs: [
                {
                    name: 'Desktop',
                    key: 'desktop',
                    icon: 'fa-desktop'
                },
                {
                    name: 'Tablet',
                    key: 'tablet',
                    icon: 'fa-tablet-alt'
                },
                {
                    name: 'Mobile',
                    key: 'mobile',
                    icon: 'fa-mobile-alt'
                },
            ],
            size: {
                width: 1000,
                height: 650
            },
            mobileSelected: '',
            tabletSelected: '',
            tabletDevices: [
                {
                    text: 'iPad Air (768x1024)',
                    width: 1024,
                    height: 768
                },
                {
                    text: 'iPad Mini 2 with Retina (768x1024)',
                    width: 768,
                    height: 1024
                },
                {
                    text: 'iPad Mini (768x1024)',
                    width: 768,
                    height: 1024
                },
                {
                    text: 'Samsung Galaxy Note Pro 12.1 (800x1280)',
                    width: 800,
                    height: 1280
                },
                {
                    text: 'Samsung Galaxy Tab S (1600x2560)',
                    width: 1600,
                    height: 2560
                },
                {
                    text:  'Samsung Galaxy Tab Pro 8.9 (800x1280)',
                    width: 800,
                    height: 1280
                },
                {
                    text: 'Sony Xperia Z2 Tablet (800x1280)',
                    width: 800,
                    height: 1280
                },
                {
                    text: 'Nexus 7 (600x960)',
                    width: 600,
                    height: 960
                },
                {
                    text: 'Nexus 5 (360x640)',
                    width: 360,
                    height: 640
                },
                {
                    text: 'Kindle Fire HDX 7" (800x960)',
                    width: 800,
                    height: 960
                }
            ],
            mobileDevices: [
                {
                    text: 'iPhone 6 Plus (414x736)',
                    width: 414,
                    height: 736
                },
                {
                    text: 'iPhone 6 (375x667)',
                    width: 375,
                    height: 667
                },
                {
                    text: 'iPhone 5 / 5C / 5S (320x568)',
                    width: 320,
                    height: 658
                },
                {
                    text: 'iPhone 4 / 4S (320x480)',
                    width: 320,
                    height: 480
                },
                {
                    text: 'iPhone 3GS (320x480)',
                    width: 320,
                    height: 480
                },
                {
                    text: 'HTC One (360x640)',
                    width: 360,
                    height: 640
                },
                {
                    text: 'Sony Xperia Z1 (360x640)',
                    width: 360,
                    height: 640
                },
                {
                    text: 'LG G3 (480x853)',
                    width: 480,
                    height: 853
                },
                {
                    text: 'Samsung Galaxy S4 (360x640)',
                    width: 360,
                    height: 640
                },
                {
                    text: 'Google Nexus 5 (360x640)',
                    width: 360,
                    height: 640
                },
                {
                    text: 'Nokia Lumia 920 / 925 / 928 / 1020 (320x533)',
                    width: 320,
                    height: 533
                }
            ]
        }),

        computed: {

            posts() {
                return this.$route.params.id !== null ? this.$store.getters['socialmosaic/posts'] : {};
            },

            loading() {
                return this.$store.getters['socialmosaic/postsStatus'];
            },

            width() {
                return this.size.width + 'px';
            },

            height() {
                return this.size.height + 'px';
            },

            iframeUrl() {
                const env = Constants.APP_URL !== '' ? Constants.APP_URL : (window.location.origin + '/');

                return window.location.protocol + '//social-iframe.evolvemediallc.com?endpoint=' + env + '/api/v1/socialmosaic/' + this.$route.params.id + '/approved';
            }
        },

        watch: {
            '$route.params.id'() {
                this.init();
            }
        },

        mounted() {
            this.init();
        },

        destroyed() {
            this.$store.dispatch('socialmosaic/reset').catch(() => {});
        },

        methods: {

            init() {
                if (this.$route.params.id !== null) {
                    this.$store.dispatch('socialmosaic/reset').then(() => {
                        const promisesPreload = [];
                        promisesPreload.push(this.$store.dispatch('socialmosaic/getFeedTypes'));
                        promisesPreload.push(this.$store.dispatch('socialmosaic/get', this.$route.params.id));
                        promisesPreload.push(this.$store.dispatch('socialmosaic/getPosts', {
                            socialMosaicId: this.$route.params.id,
                            action: 'approved'
                        }));

                        Promise.all(promisesPreload).then(() => {
                            this.ready = true;
                        }).catch(() => {});
                    });
                } else {
                    this.ready = true;
                }
            },

            sizeSelected(device, devices) {
                const find = _.find(devices, {text: device});
                this.size.width = find.width;
                this.size.height = find.height;
            }
        }
    }
</script>
