<template>
    <div>
        <v-card-title class="headline">
            Blocked Words
        </v-card-title>

        <v-card-text>
            <v-progress-circular
                v-if="loading === 'loading'"
                indeterminate
                color="primary"
            ></v-progress-circular>
            <v-simple-table v-if="loading === 'loaded'">
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-center">WORD</th>
                            <th class="text-center">ACTIONS</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="tempBlockedWord in tempBlockedWords" :key="tempBlockedWord.word">
                            <td class="text-center">
                                {{ tempBlockedWord.word }}
                            </td>
                            <td class="text-center">
                                <v-btn
                                    @click.native="destroy(tempBlockedWord.id)"
                                    icon="icon"
                                ><v-icon class="text-info">fa-trash-alt</v-icon></v-btn>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>

            <v-form ref="form" v-model="formValid">
                <v-row align="center" justify="space-between">
                    <v-col>
                        <v-text-field
                            label="Post"
                            v-model="blockedWord.word"
                            :rules="[rules.required]"
                        ></v-text-field>
                    </v-col>
                    <v-col>
                        <v-btn
                            color="primary"
                            @click="add"
                            :loading="isSaving"
                        >Add</v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
    </div>
</template>

<script>
    import _ from 'lodash';

    export default {

        name: 'BlockedWords',

        props: ['id'],

        computed: {

            blockedWords() {

                return this.$store.getters['socialmosaic/blockedWords'];
            },

            loading() {

                return this.$store.getters['socialmosaic/blockedWordsStatus'];
            }
        },

        data: () => ({
            formValid: true,
            blockedWord: {
                word: ''
            },
            rules: {
                required: value => !!value || 'Required',
                counter: value => value.length >= 5 || 'Min 5 characters'
            },
            tempBlockedWords: [],
            isSaving: false
        }),

        updated() {
            this.tempBlockedWords = _.cloneDeep(this.blockedWords);
        },

        methods: {

            destroy(blockedWordId) {
                this.$store.dispatch('socialmosaic/deleteBlockedWord', {
                    socialMosaicId: this.id,
                    blockedWordId
                }).then(() => {
                    this.$store.dispatch('socialmosaic/getBlockedWords', this.id).then(() => {
                        this.tempBlockedWords = _.cloneDeep(this.blockedWords);
                    }).catch(() => {});
                }).catch(() => {});
            },

            add() {
                this.$refs.form.validate();

                if (this.formValid) {
                    this.isSaving = true;
                    const blockedWord = _.cloneDeep(this.blockedWord);

                    this.$store.dispatch('socialmosaic/newBlockedWord', {
                        socialMosaicId: this.id,
                        blockedWord
                    }).then(() => {
                        this.$store.dispatch('socialmosaic/getBlockedWords', this.id).then(() => {
                            this.tempBlockedWords = _.cloneDeep(this.blockedWords);
                            this.isSaving = false;
                        }).catch(() => {});
                    }).catch(() => {});
                }
            }
        }
    }
</script>
