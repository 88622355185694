<template>
    <div class="creative creative--social-mosaic">
        <f-creative-container
            v-if="ready && (type === 'basic' || type === 'feed')"
            :id="id"
            :tabs="tabs[type]"
            :data="data"
        ></f-creative-container>
        <f-social-mosaic-preview
            v-if="type === 'preview'"
            :id="id"
            :data="data"
        ></f-social-mosaic-preview>
    </div>
</template>

<script>
    import _ from 'lodash';
    import CreativeContainer from '@/views/creatives/components/CreativeContainer';

    // Basic
    import Edit from '@/views/creatives/socialmosaic/components/Edit';
    import Filters from '@/views/creatives/socialmosaic/components/Filters';
    import CustomUploads from '@/views/creatives/socialmosaic/components/CustomUploads';
    import Customization from '@/views/creatives/socialmosaic/components/Customization';
    import Sharing from '@/views/creatives/socialmosaic/components/Sharing';
    import Placement from '@/views/creatives/socialmosaic/components/Placement';

    // Feed
    import Pending from '@/views/creatives/socialmosaic/components/Pending';
    import Approved from '@/views/creatives/socialmosaic/components/Approved';
    import Rejected from '@/views/creatives/socialmosaic/components/Rejected';

    // Preview
    import Preview from '@/views/creatives/socialmosaic/components/Preview';

    export default {

        name: 'SocialMosaic',

        data: () => ({
            id: null,
            type: null,
            tabs: {
                basic: [
                    {
                        name: 'New',
                        key: 'edit',
                        icon: 'fa-plus-square',
                        component: Edit,
                        disabled: false
                    },
                    {
                        name: 'Filters',
                        key: 'filters',
                        icon: 'fa-filter',
                        component: Filters,
                        disabled: false
                    },
                    {
                        name: 'Custom Uploads',
                        key: 'custom-uploads',
                        icon: 'fa-upload',
                        component: CustomUploads,
                        disabled: false
                    },
                    {
                        name: 'Customization',
                        key: 'customization',
                        icon: 'fa-palette',
                        component: Customization,
                        disabled: false
                    },
                    {
                        name: 'Sharing',
                        key: 'sharing',
                        icon: 'fa-share-square',
                        component: Sharing,
                        disabled: false
                    },
                    {
                        name: 'Placement',
                        key: 'placement',
                        icon: 'fa-road',
                        component: Placement,
                        disabled: false
                    }
                ],
                feed: [
                    {
                        name: 'Pending',
                        key: 'feed-pending',
                        icon: 'fa-spinner',
                        component: Pending,
                        disabled: false
                    },
                    {
                        name: 'Approved',
                        key: 'feed-approved',
                        icon: 'fa-thumbs-up',
                        component: Approved,
                        disabled: false
                    },
                    {
                        name: 'Rejected',
                        key: 'feed-rejected',
                        icon: 'fa-thumbs-down',
                        component: Rejected,
                        disabled: false
                    }
                ]
            },
            ready: false
        }),

        computed: {

            data() {
                return this.id !== null ? this.$store.getters['socialmosaic/data'] : {};
            }
        },

        watch: {
            $route() {
               this.init();
            }
        },

        mounted() {
            this.init();
        },

        destroyed() {
            this.$store.dispatch('socialmosaic/reset').catch(() => {});
        },

        methods: {

            init() {
                if (!_.isUndefined(this.$route.params.selectedTab)) {
                    if (this.$route.params.selectedTab === 'preview') {
                        this.type = 'preview';
                        return;
                    }
                    _.each(this.tabs, (tabGroup, tabGroupIndex) => {
                        _.each(tabGroup, tab => {
                            if (tab.key === this.$route.params.selectedTab) {
                                const newType = tabGroupIndex;

                                if (newType !== this.type) {
                                    this.ready = false;
                                    this.type = newType;

                                    if (this.type === 'basic') {
                                        this.initBasic();
                                    } else {
                                        this.initFeed();
                                    }
                                } else {
                                    if (this.type === 'feed') {
                                        this.initFeed();
                                    } else if (this.type === 'basic') {
                                        this.setInitialData();
                                    }
                                }
                            }
                        });
                    });
                } else if (
                    _.isUndefined(this.$route.params.selectedTab)
                    && this.$route.params.id === 'new'
                ) {
                    this.type = 'basic';
                    this.initBasic();
                }
            },

            setInitialData() {
                this.id = (this.$route.params.id === 'new' ? null : this.$route.params.id);

                if (this.type === 'basic') {
                    this.tabs.basic[0].name = this.id === null ? 'New' : 'Edit';
                    this.tabs.basic[0].icon = this.id === null ? 'fa-plus-square' : 'fa-edit';

                    this.tabs.basic.forEach((tab, index) => {
                        if (index > 0) {
                            tab.disabled = this.id === null;
                        }
                    });
                }
            },

            initBasic() {
                this.setInitialData();

                this.$store.dispatch('socialmosaic/reset').then(() => {
                    if (this.id === null) {
                        this.ready = true;
                        return;
                    }

                    const promisesPreload = [];
                    promisesPreload.push(this.$store.dispatch('socialmosaic/get', this.id));
                    promisesPreload.push(this.$store.dispatch('socialmosaic/getFeedTypes', this.id));
                    promisesPreload.push(this.$store.dispatch('socialmosaic/getLangs', this.id));

                    Promise.all(promisesPreload).then(() => {
                        this.ready = true;

                        // Filters
                        this.$store.dispatch('socialmosaic/getHashtags', this.id).catch(() => {});
                        this.$store.dispatch('socialmosaic/getAccounts', this.id).catch(() => {});
                        this.$store.dispatch('socialmosaic/getIndividualPosts', this.id).catch(() => {});
                        this.$store.dispatch('socialmosaic/getBlockedWords', this.id).catch(() => {});
                        this.$store.dispatch('socialmosaic/getBlockedUsers', this.id).catch(() => {});
                        this.$store.dispatch('socialmosaic/getLanguages', this.id).catch(() => {});

                        this.$store.dispatch('socialmosaic/getSharingProviderTypes', this.id).catch(() => {});
                        this.$store.dispatch('socialmosaic/getSharings', this.id).catch(() => {});
                        this.$store.dispatch('socialmosaic/getPlacements', this.id).catch(() => {});
                    }).catch(() => {});
                }).catch(() => {});
            },

            initFeed() {
                this.setInitialData();

                this.$store.dispatch('socialmosaic/reset').then(() => {
                    const promisesPreload = [];
                    const tab = this.$route.params.selectedTab;
                    const action = tab.substring(tab.lastIndexOf('-') + 1);

                    promisesPreload.push(this.$store.dispatch('socialmosaic/getFeedTypes'));
                    promisesPreload.push(this.$store.dispatch('socialmosaic/get', this.$route.params.id));
                    promisesPreload.push(this.$store.dispatch('socialmosaic/getPosts', {
                        socialMosaicId: this.$route.params.id,
                        action,
                        keywords: ''
                    }));

                    Promise.all(promisesPreload).then(() => {
                        this.ready = true;
                    }).catch(() => {});
                });
            }
        },

        components: {
            FCreativeContainer: CreativeContainer,
            FSocialMosaicPreview: Preview
        }
    }
</script>
