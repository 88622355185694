<template>
    <div>
        <v-card-title class="headline">
            Individual Posts
        </v-card-title>

        <v-card-text>
            <v-progress-circular
                v-if="loading === 'loading'"
                indeterminate
                color="primary"
            ></v-progress-circular>
            <v-simple-table v-if="loading === 'loaded'">
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-center">POST</th>
                            <th class="text-center">TWITTER</th>
                            <th class="text-center">INSTAGRAM</th>
                            <th class="text-center">VINE</th>
                            <th class="text-center">ACTIONS</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="tempIndividualPost in tempIndividualPosts" :key="tempIndividualPost.individualPostFilterName">
                            <td class="text-center">
                                {{ tempIndividualPost.individualPostFilterName }}
                            </td>
                            <td class="text-center">
                                <v-icon
                                    :color="tempIndividualPost.type !== 'twitter' ? 'grey darken-3': 'blue'"
                                >fab fa-twitter-square</v-icon>
                            </td>
                            <td class="text-center">
                                <v-icon
                                    :color="tempIndividualPost.type !== 'instagram' ? 'grey darken-3': 'orange darken-3'"
                                >fab fa-instagram</v-icon>
                            </td>
                            <td class="text-center">
                                <v-icon
                                    :color="tempIndividualPost.type !== 'vine' ? 'grey darken-3': 'teal lighten-1'"
                                >fab fa-vine</v-icon>
                            </td>
                            <td class="text-center">
                                <v-btn
                                    @click.native="destroy(tempIndividualPost.id)"
                                    icon="icon"
                                ><v-icon class="text-info">fa-trash-alt</v-icon></v-btn>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>

            <v-form ref="form" v-model="formValid">
                <v-row align="center" justify="space-between">
                    <v-col>
                        <v-text-field
                            label="Post"
                            v-model="individualPost.individualPostFilterName"
                            :rules="[rules.required]"
                        ></v-text-field>
                    </v-col>
                    <v-col>
                        <v-radio-group v-model="individualPost.feeds_type_id">
                            <v-radio
                                label="Twitter"
                                :value="getValue('twitter')"
                            ></v-radio>
                        </v-radio-group>
                    </v-col>
                    <v-col>
                        <v-btn
                            color="primary"
                            @click="add"
                            :loading="isSaving"
                        >Add</v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
    </div>
</template>

<script>
    import _ from 'lodash';

    export default {

        name: 'IndividualPosts',

        props: ['id'],

        computed: {

            feedTypes() {

                return this.$store.getters['socialmosaic/feedTypes'];
            },

            individualPosts() {

                return this.$store.getters['socialmosaic/individualPosts'];
            },

            loading() {

                return this.$store.getters['socialmosaic/individualPostsStatus'];
            }
        },

        data: () => ({
            formValid: true,
            individualPost: {
                individualPostFilterName: '',
                feeds_type_id: 1
            },
            rules: {
                required: value => !!value || 'Required',
                counter: value => value.length >= 5 || 'Min 5 characters'
            },
            tempIndividualPosts: [],
            isSaving: false
        }),

        updated() {
            this.tempIndividualPosts = _.cloneDeep(this.individualPosts);
        },

        methods: {

            getValue(type) {
                const find = _.find(this.feedTypes, {type});

                return !_.isUndefined(find) ? find.id : null;
            },

            destroy(individualPostId) {
                this.$store.dispatch('socialmosaic/deleteIndividualPost', {
                    socialMosaicId: this.id,
                    individualPostId
                }).then(() => {
                    this.$store.dispatch('socialmosaic/getIndividualPosts', this.id).then(() => {
                        this.tempIndividualPosts = _.cloneDeep(this.individualPosts);
                    }).catch(() => {});
                }).catch(() => {});
            },

            add() {
                this.$refs.form.validate();

                if (this.formValid) {
                    this.isSaving = true;
                    const individualPost = _.cloneDeep(this.individualPost);

                    this.$store.dispatch('socialmosaic/newIndividualPost', {
                        socialMosaicId: this.id,
                        individualPost
                    }).then(() => {
                        this.$store.dispatch('socialmosaic/getIndividualPosts', this.id).then(() => {
                            this.tempIndividualPosts = _.cloneDeep(this.individualPosts);
                            this.isSaving = false;
                        }).catch(() => {});
                    }).catch(() => {});
                }
            }
        }
    }
</script>
