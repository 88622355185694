<template>
    <div>
        <v-card-title class="headline">
            Blocked Users
        </v-card-title>

        <v-card-text>
            <v-progress-circular
                v-if="loading === 'loading'"
                indeterminate
                color="primary"
            ></v-progress-circular>
            <v-simple-table v-if="loading === 'loaded'">
                <template v-slot:default>
                    <thead>
                    <tr>
                        <th class="text-center">USERNAME</th>
                        <th class="text-center">ACTIONS</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="tempBlockedUser in tempBlockedUsers" :key="tempBlockedUser.name">
                        <td class="text-center">
                            {{ tempBlockedUser.name }}
                        </td>
                        <td class="text-center">
                            <v-btn
                                @click.native="destroy(tempBlockedUser.id)"
                                icon="icon"
                            ><v-icon class="text-info">fa-trash-alt</v-icon></v-btn>
                        </td>
                    </tr>
                    </tbody>
                </template>
            </v-simple-table>

            <v-form ref="form" v-model="formValid">
                <v-row align="center" justify="space-between">
                    <v-col>
                        <v-text-field
                            label="Username"
                            v-model="blockedUser.name"
                            :rules="[rules.required]"
                        ></v-text-field>
                    </v-col>
                    <v-col>
                        <v-radio-group v-model="blockedUser.feeds_type_id">
                            <v-radio
                                label="Twitter"
                                :value="getValue('twitter')"
                            ></v-radio>
                        </v-radio-group>
                    </v-col>
                    <v-col>
                        <v-btn
                            color="primary"
                            @click="add"
                            :loading="isLoading"
                        >Add</v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
    </div>
</template>

<script>
    import _ from 'lodash';

    export default {

        name: 'BlockedUsers',

        props: ['id'],

        computed: {

            feedTypes() {

                return this.$store.getters['socialmosaic/feedTypes'];
            },

            blockedUsers() {

                return this.$store.getters['socialmosaic/blockedUsers'];
            },

            loading() {

                return this.$store.getters['socialmosaic/blockedUsersStatus'];
            }
        },

        data: () => ({
            formValid: true,
            blockedUser: {
                feeds_type_id: 1,
                name: ''
            },
            rules: {
                required: value => !!value || 'Required',
                counter: value => value.length >= 5 || 'Min 5 characters'
            },
            tempBlockedUsers: [],
            isLoading: false
        }),

        updated() {
            this.tempBlockedUsers = _.cloneDeep(this.blockedUsers);
        },

        methods: {

            getValue(type) {
                const find = _.find(this.feedTypes, {type});

                return !_.isUndefined(find) ? find.id : null;
            },

            destroy(blockedUserId) {
                this.$store.dispatch('socialmosaic/deleteBlockedUser', {
                    socialMosaicId: this.id,
                    blockedUserId
                }).then(() => {
                    this.$store.dispatch('socialmosaic/getBlockedUsers', this.id).then(() => {
                        this.tempBlockedUsers = _.cloneDeep(this.blockedUsers);
                    }).catch(() => {});
                }).catch(() => {});
            },

            add() {
                this.$refs.form.validate();

                if (this.formValid) {
                    this.isLoading = true;
                    const blockedUser = _.cloneDeep(this.blockedUser);

                    this.$store.dispatch('socialmosaic/newBlockedUser', {
                        socialMosaicId: this.id,
                        blockedUser
                    }).then(() => {
                        this.$store.dispatch('socialmosaic/getBlockedUsers', this.id).then(() => {
                            this.tempBlockedUsers = _.cloneDeep(this.blockedUsers);
                            this.isLoading = false;
                        }).catch(() => {});
                    }).catch(() => {});
                }
            }
        }
    }
</script>
