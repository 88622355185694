<template>
    <v-card color="#303030" class="pt-10 pb-10 pr-10 pl-10">
        <v-form ref="formBasic" v-model="formBasicValid">
            <v-row>
                <v-col>
                    <v-text-field
                        label="Application Title"
                        v-model="inputs.title"
                        :rules="[rules.required, rules.counter]"
                        outlined
                    ></v-text-field>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <v-menu
                        v-model="menu.start_date"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="menu.start_date_formatted"
                                label="Start Date"
                                hint="MM/DD/YYYY format"
                                persistent-hint
                                prepend-icon="fa-calendar-alt"
                                @blur="inputs.start_date = dateService.parseDate(menu.start_date_formatted)"
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="inputs.start_date"
                            no-title
                            @input="menu.start_date = false"
                        ></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col>
                    <v-menu
                        v-model="menu.end_date"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="menu.end_date_formatted"
                                label="End Date"
                                hint="MM/DD/YYYY format"
                                persistent-hint
                                prepend-icon="fa-calendar-alt"
                                @blur="inputs.end_date = dateService.parseDate(menu.end_date_formatted)"
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="inputs.end_date"
                            no-title
                            @input="menu.end_date = false"
                        ></v-date-picker>
                    </v-menu>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <v-btn text :to="'/dashboard'">Cancel</v-btn>
                </v-col>
                <v-col>
                    <v-btn
                        color="primary"
                        @click="saveBasic"
                        :loading="isSavingBasic"
                    >Save</v-btn>
                </v-col>
            </v-row>
        </v-form>
    </v-card>
</template>

<script>
    import _ from 'lodash';
    import DateService from '@/services/date.service';

    export default {

        name: 'Edit',

        props: ['id', 'data'],

        data: () => ({
            dateService: DateService,
            formBasicValid: true,
            inputs: {
                title: '',
                start_date: new Date().toISOString().substr(0, 10),
                end_date: DateService.addDays(new Date(), 15).toISOString().substr(0, 10)
            },
            menu: {
                start_date: false,
                start_date_formatted: DateService.formatDate(new Date().toISOString().substr(0, 10)),
                end_date: false,
                end_date_formatted: DateService.formatDate(DateService.addDays(new Date(), 15).toISOString().substr(0, 10))
            },
            rules: {
                required: value => !!value || 'Required',
                counter: value => value.length >= 5 || 'Min 5 characters'
            },
            isSavingBasic: false
        }),

        watch: {

            'inputs.start_date'() {
                this.menu.start_date_formatted = DateService.formatDate(this.inputs.start_date);
            },

            'inputs.end_date'() {
                this.menu.end_date_formatted = DateService.formatDate(this.inputs.end_date);
            }
        },

        mounted() {
            this.isSavingBasic = false;

            if (localStorage.getItem('flowUpdate') !== null) {
                localStorage.removeItem('flowUpdate');
            }

            if (this.id !== null) {
                this.setInputValues();
            }
        },

        methods: {

            setInputValues() {
                _.each(this.inputs, (v, k) => {
                    if (k.indexOf('date') > -1) {
                        this.inputs[k] = new Date(this.data[k]).toISOString().substr(0, 10);
                    } else {
                        this.inputs[k] = this.data[k];
                    }
                });

                this.tempArticles = _.cloneDeep(this.articles);
            },

            saveBasic() {
                this.isSavingBasic = true;
                this.$refs.formBasic.validate();

                if (this.formBasicValid) {
                    const action = this.id === null ? 'new' : 'update';
                    const inputs = _.cloneDeep(this.inputs);
                    inputs.start_date += ' 00:00:00';
                    inputs.end_date += ' 23:59:59';
                    const data = this.id === null ? inputs : {
                        id: this.id,
                        data: inputs
                    };

                    this.$store.dispatch('socialmosaic/' + action, data).then(response => {
                        this.$store.dispatch('socialmosaic/get', response.id).then(() => {
                            this.isSavingBasic = false;

                            if (action === 'new') {
                                localStorage.setItem('flowUpdate', true);
                                this.$router.replace({
                                    name: 'socialmosaic',
                                    params: {
                                        id: response.id,
                                        selectedTab: 'edit'
                                    }
                                });
                            }
                        }).catch(() => {});
                    }).catch(() => {});
                } else{
                    this.isSavingBasic = false;
                }
            }
        }
    }
</script>

<style lang="scss">
    .hidden-textarea {
        color: red;

        .v-input__slot {
            display: none !important;
        }
    }
</style>
