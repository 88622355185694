<template>
    <v-card color="#303030" class="pt-10 pb-10 pr-10 pl-10 entries">
        <v-card-text>
            <v-form ref="form" v-model="formValid">
                <v-row align="center" justify="center">
                    <v-col cols="6">
                        <v-file-input
                            @change="onFileChanged('fixed_tileimg', $event)"
                            label="Fixed Tile Image (jpg | png)"
                            outlined
                            placeholder="Select your image"
                            accept=".png, .jpg, .jpeg"
                        ></v-file-input>
                    </v-col>
                    <v-col cols="6">
                        <v-img
                            v-if="previews.fixed_tileimg !== ''"
                            class="mt-1"
                            :src="previews.fixed_tileimg"
                            contain
                            max-height="125"
                        ></v-img>
                        <v-img
                            v-else
                            class="mt-1"
                            src="https://via.placeholder.com/300x125/CCCCCC/333333/?text=PLACEHOLDER"
                            contain
                            max-height="125"
                        ></v-img>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field
                            label="Text Color"
                            v-model="inputs.text_color"
                            v-mask="masks.text_color"
                            outlined
                            placeholder="# Hex Color"
                        >
                            <template v-slot:append>
                                <v-menu
                                    v-model="menus.text_color"
                                    top nudge-bottom="105"
                                    nudge-left="16"
                                    :close-on-content-click="false"
                                >
                                    <template v-slot:activator="{ on }">
                                        <div
                                            :style="swatchStyle(inputs.text_color, menus.text_color)"
                                            v-on="on"
                                        />
                                    </template>
                                    <v-card>
                                        <v-card-text class="pa-0">
                                            <v-color-picker
                                                v-model="inputs.text_color"
                                                flat
                                            />
                                        </v-card-text>
                                    </v-card>
                                </v-menu>
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field
                            label="Highlight Color"
                            v-model="inputs.highlight_color"
                            v-mask="masks.highlight_color"
                            outlined
                            placeholder="# Hex Color"
                        >
                            <template v-slot:append>
                                <v-menu
                                    v-model="menus.highlight_color"
                                    top nudge-bottom="105"
                                    nudge-left="16"
                                    :close-on-content-click="false"
                                >
                                    <template v-slot:activator="{ on }">
                                        <div
                                            :style="swatchStyle(inputs.highlight_color, menus.highlight_color)"
                                            v-on="on"
                                        />
                                    </template>
                                    <v-card>
                                        <v-card-text class="pa-0">
                                            <v-color-picker
                                                v-model="inputs.highlight_color"
                                                flat
                                            />
                                        </v-card-text>
                                    </v-card>
                                </v-menu>
                            </template>
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field
                            label="Background Color"
                            v-model="inputs.bg_color"
                            v-mask="masks.bg_color"
                            outlined
                            placeholder="# Hex Color"
                        >
                            <template v-slot:append>
                                <v-menu
                                    v-model="menus.bg_color"
                                    top nudge-bottom="105"
                                    nudge-left="16"
                                    :close-on-content-click="false"
                                >
                                    <template v-slot:activator="{ on }">
                                        <div
                                            :style="swatchStyle(inputs.bg_color, menus.bg_color)"
                                            v-on="on"
                                        />
                                    </template>
                                    <v-card>
                                        <v-card-text class="pa-0">
                                            <v-color-picker
                                                v-model="inputs.bg_color"
                                                flat
                                            />
                                        </v-card-text>
                                    </v-card>
                                </v-menu>
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field
                            label="Tile Background Color"
                            v-model="inputs.tile_bg_color"
                            v-mask="masks.tile_bg_color"
                            outlined
                            placeholder="# Hex Color"
                        >
                            <template v-slot:append>
                                <v-menu
                                    v-model="menus.tile_bg_color"
                                    top nudge-bottom="105"
                                    nudge-left="16"
                                    :close-on-content-click="false"
                                >
                                    <template v-slot:activator="{ on }">
                                        <div
                                            :style="swatchStyle(inputs.tile_bg_color, menus.tile_bg_color)"
                                            v-on="on"
                                        />
                                    </template>
                                    <v-card>
                                        <v-card-text class="pa-0">
                                            <v-color-picker
                                                v-model="inputs.tile_bg_color"
                                                flat
                                            />
                                        </v-card-text>
                                    </v-card>
                                </v-menu>
                            </template>
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row align="center" justify="center">
                    <v-col cols="6">
                        <v-file-input
                            @change="onFileChanged('tile_bg_image', $event)"
                            label="Tile Background Image (jpg | png)"
                            outlined
                            placeholder="Select your image"
                            accept=".png, .jpg, .jpeg"
                        ></v-file-input>
                    </v-col>
                    <v-col cols="6">
                        <v-img
                            v-if="previews.tile_bg_image !== ''"
                            class="mt-1"
                            :src="previews.tile_bg_image"
                            contain
                            max-height="125"
                        ></v-img>
                        <v-img
                            v-else
                            class="mt-1"
                            src="https://via.placeholder.com/300x125/CCCCCC/333333/?text=PLACEHOLDER"
                            contain
                            max-height="125"
                        ></v-img>
                    </v-col>
                </v-row>
                <v-row align="center" justify="center">
                    <v-col cols="6">
                        <v-file-input
                            @change="onFileChanged('bak_tileimg', $event)"
                            label="Backup Tile Image (jpg | png)"
                            outlined
                            placeholder="Select your image"
                            accept=".png, .jpg, .jpeg"
                        ></v-file-input>
                    </v-col>
                    <v-col cols="6">
                        <v-img
                            v-if="previews.bak_tileimg !== ''"
                            class="mt-1"
                            :src="previews.bak_tileimg"
                            contain
                            max-height="125"
                        ></v-img>
                        <v-img
                            v-else
                            class="mt-1"
                            src="https://via.placeholder.com/300x125/CCCCCC/333333/?text=PLACEHOLDER"
                            contain
                            max-height="125"
                        ></v-img>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-select
                            :items="animations"
                            label="Animation"
                            outlined
                            item-text="text"
                            item-value="value"
                            v-model="inputs.animate"
                        ></v-select>
                    </v-col>
                    <v-col align="center" justify="end">
                        <v-btn
                            color="primary"
                            @click="save"
                        >Save</v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
    </v-card>
</template>

<script>
    import _ from 'lodash';

    export default {

        name: 'Customization',

        props: ['id', 'data'],

        data: () => ({
            formValid: true,
            masks: {
                text_color: '!#XXXXXXXX',
                highlight_color: '!#XXXXXXXX',
                bg_color: '!#XXXXXXXX',
                tile_bg_color: '!#XXXXXXXX'
            },
            menus: {
                fixed_tileimg: false,
                tile_bg_image: false,
                bak_tileimg: false
            },
            inputs: {
                fixed_tileimg: '',
                text_color: '#000000',
                highlight_color: '#000000',
                bg_color: '#000000',
                tile_bg_color: '#000000',
                tile_bg_image: '',
                bak_tileimg: '',
                animate: ''
            },
            previews: {
                fixed_tileimg: '',
                tile_bg_image: '',
                bak_tileimg: ''
            },
            animations: [
                {
                    value: '',
                    text: 'None'
                },
                {
                    value: 'zoom',
                    text: 'Zoom'
                }
            ]
        }),

        mounted() {
            this.setInputValues();
        },

        methods: {

            setInputValues() {
                _.each(this.inputs, (v, k) => {
                    this.inputs[k] = this.data[k];

                    if (
                        (
                            k.indexOf('img') > -1
                            || k.indexOf('image') > -1
                        ) && this.inputs[k] !== ''
                    ) {
                        this.previews[k] = this.inputs[k];
                    }
                });
            },

            swatchStyle(color, menu) {

                return {
                    backgroundColor: color,
                    cursor: 'pointer',
                    height: '30px',
                    width: '30px',
                    borderRadius: menu ? '50%' : '4px',
                    transition: 'border-radius 200ms ease-in-out'
                };
            },

            onFileChanged(control, evt) {
                if (typeof evt === 'undefined') {
                    this.inputs[control] = '';
                } else {
                    this.inputs[control] = evt;
                    this.toPreview(control);
                }
            },

            toPreview(control) {
                const reader = new FileReader();

                reader.onload = e => {
                    this.previews[control] = e.target.result;
                };

                reader.readAsDataURL(this.inputs[control]);
            },

            save() {
                this.$refs.form.validate();

                if (this.formValid) {
                    const inputs = _.cloneDeep(this.inputs);
                    const data = new FormData();
                    data.append('text_color', inputs.text_color);
                    data.append('highlight_color', inputs.highlight_color);
                    data.append('bg_color', inputs.bg_color);
                    data.append('tile_bg_color', inputs.tile_bg_color);
                    data.append('animate', inputs.animate);

                    if (typeof inputs.fixed_tileimg === 'object') {
                        data.append('fixed_tileimg', inputs.fixed_tileimg);
                    }
                    if (typeof inputs.tile_bg_image === 'object') {
                        data.append('img', inputs.tile_bg_image);
                    }
                    if (typeof inputs.bak_tileimg === 'object') {
                        data.append('img', inputs.bak_tileimg);
                    }

                    this.$store.dispatch('socialmosaic/customize', {
                        socialMosaicId: this.id,
                        data
                    }).then(() => {
                        this.$store.dispatch('socialmosaic/get', this.id).catch(() => {});
                    }).catch(() => {});
                }
            }
        }
    }
</script>
