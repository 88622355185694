<template>
    <v-card color="#303030" class="pt-10 pb-10 pr-10 pl-10">
        <v-card-text>

            <v-progress-circular
                v-if="loading === 'loading'"
                indeterminate
                color="primary"
                class="mt-10 mb-10"
            ></v-progress-circular>

            <v-simple-table v-if="loading === 'loaded'">
                <template v-slot:default>
                    <thead>
                    <tr>
                        <th class="text-center">PLACEMENT</th>
                        <th class="text-center">GRID A</th>
                        <th class="text-center">GRID B</th>
                        <th class="text-center">GRID C</th>
                        <th class="text-center">ORIGIN</th>
                        <th class="text-center">ACTIONS</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(tempPlacement, tempPlacementIndex) in tempPlacements" :key="tempPlacementIndex">
                        <td class="text-center">
                            {{ tempPlacement.placement }}
                        </td>
                        <td class="text-center">
                            <v-icon
                                :color="tempPlacement.grid !== 'A' ? 'grey darken-3': 'pink'"
                            >{{ tempPlacement.grid !== 'A' ? 'fas fa-circle' : 'far fa-circle'}}</v-icon>
                        </td>
                        <td class="text-center">
                            <v-icon
                                :color="tempPlacement.grid !== 'B' ? 'grey darken-3': 'pink'"
                            >{{ tempPlacement.grid !== 'B' ? 'fas fa-circle' : 'far fa-circle'}}</v-icon>
                        </td>
                        <td class="text-center">
                            <v-icon
                                :color="tempPlacement.grid !== 'C' ? 'grey darken-3': 'pink'"
                            >{{ tempPlacement.grid !== 'C' ? 'fas fa-circle' : 'far fa-circle'}}</v-icon>
                        </td>
                        <td class="text-center">
                            {{ tempPlacement.ea_origin ? tempPlacement.ea_adId : '' }}
                        </td>
                        <td class="text-center">
                            <v-btn
                                @click="destroy(tempPlacement.id)"
                                icon="icon"
                            ><v-icon class="text-info">fa-trash-alt</v-icon></v-btn>
                        </td>
                    </tr>
                    </tbody>
                </template>
            </v-simple-table>

            <br>
            <v-divider></v-divider>
            <br>

            <v-form ref="form" v-model="formValid">
                <v-row align="center" justify="space-between">
                    <v-col>
                        <v-text-field
                            label="Name"
                            v-model="placement.placement"
                            :rules="[rules.required]"
                        ></v-text-field>
                    </v-col>
                    <v-col>
                        <v-radio-group v-model="placement.grid">
                            <v-radio
                                label="Grid A"
                                color="pink"
                                :value="'A'"
                            ></v-radio>
                            <v-radio
                                label="Grid B"
                                color="pink"
                                :value="'B'"
                            ></v-radio>
                            <v-radio
                                label="Grid C"
                                color="pink"
                                :value="'C'"
                            ></v-radio>
                        </v-radio-group>
                    </v-col>
                    <v-col>
                        <v-switch
                            v-model="placement.ea_origin"
                            label="Origin"
                        ></v-switch>
                    </v-col>
                    <v-col>
                        <v-text-field
                            v-if="placement.ea_origin"
                            label="Ad Id"
                            v-model="placement.ea_adId"
                        ></v-text-field>
                    </v-col>
                    <v-col>
                        <v-btn
                            color="primary"
                            @click="add"
                            :loading="isSaving"
                        >Add</v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
    </v-card>
</template>

<script>
    import _ from 'lodash';

    export default {

        name: 'Placement',

        props: ['id', 'data'],

        computed: {

            loading() {
                return this.$store.getters['socialmosaic/placementStatus'];
            },

            placements() {

                return this.$store.getters['socialmosaic/placements'];
            }
        },

        data: () => ({
            formValid: true,
            placement: {
                placement: '',
                grid: 'A',
                ea_origin: 0,
                ea_adId: ''
            },
            rules: {
                required: value => !!value || 'Required'
            },
            tempPlacements: [],
            isSaving: false
        }),

        updated() {
            this.tempPlacements = _.cloneDeep(this.placements);
        },

        methods: {

            destroy(placementId) {
                this.$store.dispatch('socialmosaic/deletePlacement', {
                    socialMosaicId: this.id,
                    placementId
                }).then(() => {
                    this.$store.dispatch('socialmosaic/getPlacements', this.id).then(() => {
                        this.tempPlacements = _.cloneDeep(this.placements);
                    }).catch(() => {});
                }).catch(() => {});
            },

            add() {
                this.isSaving = true;
                this.$refs.form.validate();

                if (this.formValid) {
                    const placement = _.cloneDeep(this.placement);
                    placement.ea_origin = placement.ea_origin ? 1 : 0;

                    this.$store.dispatch('socialmosaic/newPlacement', {
                        socialMosaicId: this.id,
                        placement
                    }).then(() => {
                        this.$store.dispatch('socialmosaic/getPlacements', this.id).then(() => {
                            this.tempPlacements = _.cloneDeep(this.placements);
                            this.isSaving = false;
                        }).catch(() => {});
                    }).catch(() => {});
                } else {
                    this.isSaving = false;
                }
            }
        }
    }
</script>
