<template>
    <v-card color="#303030" class="pt-10 pb-10 pr-10 pl-10">
        <v-card-text>
            <p>Enter the URL you want to share, select the checkbox for the social media providers to share to.</p>

            <v-progress-circular
                v-if="loading === 'loading'"
                indeterminate
                color="primary"
                class="mt-10 mb-10"
            ></v-progress-circular>

            <v-simple-table v-if="loading === 'loaded'">
                <template v-slot:default>
                    <thead>
                    <tr>
                        <th class="text-center">SHARING URL</th>
                        <th class="text-center">TWITTER</th>
                        <th class="text-center">FACEBOOK</th>
                        <th class="text-center">PINTEREST</th>
                        <th class="text-center">ACTIONS</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(tempSharing, tempSharingIndex) in tempSharings" :key="tempSharingIndex">
                        <td class="text-center">
                            {{ tempSharing.url }}
                        </td>
                        <td class="text-center">
                            <v-icon
                                :color="tempSharing.type !== 'twitter' ? 'grey darken-3': 'blue'"
                            >fab fa-twitter-square</v-icon>
                        </td>
                        <td class="text-center">
                            <v-icon
                                :color="tempSharing.type !== 'facebook' ? 'grey darken-3': 'blue darken-4'"
                            >fab fa-facebook-square</v-icon>
                        </td>
                        <td class="text-center">
                            <v-icon
                                :color="tempSharing.type !== 'pinterest' ? 'grey darken-3': 'red accent-4'"
                            >fab fa-pinterest-square</v-icon>
                        </td>
                        <td class="text-center">
                            <v-btn
                                @click="destroy(tempSharing.id)"
                                icon="icon"
                            ><v-icon class="text-info">fa-trash-alt</v-icon></v-btn>
                        </td>
                    </tr>
                    </tbody>
                </template>
            </v-simple-table>

            <br>
            <v-divider></v-divider>
            <br>

            <v-form ref="form" v-model="formValid">
                <v-row align="center" justify="space-between">
                    <v-col>
                        <v-text-field
                            label="Sharing Url"
                            v-model="sharing.url"
                            :rules="[rules.required, rules.url]"
                        ></v-text-field>
                    </v-col>
                    <v-col>
                        <v-radio-group v-model="sharing.sharing_provider_type_id">
                            <v-radio
                                label="Twitter"
                                color="blue"
                                :value="getValue('twitter')"
                            ></v-radio>
                            <v-radio
                                label="Facebook"
                                color="blue darken-4"
                                :value="getValue('facebook')"
                            ></v-radio>
                            <v-radio
                                label="Pinterest"
                                color="red"
                                :value="getValue('pinterest')"
                            ></v-radio>
                        </v-radio-group>
                    </v-col>
                    <v-col>
                        <v-btn
                            color="primary"
                            @click="add"
                            :loading="isSaving"
                        >Add</v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
    </v-card>
</template>

<script>
    import _ from 'lodash';

    export default {

        name: 'Sharing',

        props: ['id', 'data'],

        computed: {

            loading() {
                return this.$store.getters['socialmosaic/sharingStatus'];
            },

            sharingProviderTypes() {

                return this.$store.getters['socialmosaic/sharingProviderTypes'];
            },

            sharings() {

                return this.$store.getters['socialmosaic/sharings'];
            }
        },

        data: () => ({
            formValid: true,
            sharing: {
                url: '',
                sharing_provider_type_id: 1
            },
            rules: {
                required: value => !!value || 'Required',
                url: value => {
                    const pattern = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
                    return pattern.test(value) || 'Invalid url';
                }
            },
            tempSharings: [],
            isSaving: false
        }),

        updated() {
            this.tempSharings = _.cloneDeep(this.sharings);
        },

        methods: {

            getValue(type) {
                const find = _.find(this.sharingProviderTypes, {type});

                return !_.isUndefined(find) ? Number(find.id) : null;
            },

            destroy(sharingId) {
                this.$store.dispatch('socialmosaic/deleteSharing', {
                    socialMosaicId: this.id,
                    sharingId
                }).then(() => {
                    this.$store.dispatch('socialmosaic/getSharings', this.id).then(() => {
                        this.tempSharings = _.cloneDeep(this.sharings);
                    }).catch(() => {});
                }).catch(() => {});
            },

            add() {
                this.isSaving = true;
                this.$refs.form.validate();

                if (this.formValid) {
                    const sharing = _.cloneDeep(this.sharing);

                    this.$store.dispatch('socialmosaic/newSharing', {
                        socialMosaicId: this.id,
                        sharing
                    }).then(() => {
                        this.$store.dispatch('socialmosaic/getSharings', this.id).then(() => {
                            this.tempSharings = _.cloneDeep(this.sharings);
                            this.isSaving = false;
                        }).catch(() => {});
                    }).catch(() => {});
                } else {
                    this.isSaving = false;
                }
            }
        }
    }
</script>
