<template>
    <v-card color="#303030" class="pt-10 pb-10 pr-10 pl-10" v-if="id !== null">
        <f-hashtags :id="id"></f-hashtags>
        <v-divider></v-divider>
        <f-accounts :id="id"></f-accounts>
        <v-divider></v-divider>
        <f-individual-posts :id="id"></f-individual-posts>
        <v-divider></v-divider>
        <f-blocked-words :id="id"></f-blocked-words>
        <v-divider></v-divider>
        <f-blocked-users :id="id"></f-blocked-users>
        <v-divider></v-divider>
        <f-languages :id="id"></f-languages>
    </v-card>
</template>

<script>
    import Hashtags from '@/views/creatives/socialmosaic/components/filters/Hashtags.vue';
    import Accounts from '@/views/creatives/socialmosaic/components/filters/Accounts.vue';
    import IndividualPosts from '@/views/creatives/socialmosaic/components/filters/IndividualPosts.vue';
    import BlockedWords from '@/views/creatives/socialmosaic/components/filters/BlockedWords.vue';
    import BlockedUsers from '@/views/creatives/socialmosaic/components/filters/BlockedUsers.vue';
    import Languages from '@/views/creatives/socialmosaic/components/filters/Languages.vue';

    export default {

        name: 'Filters',

        props: ['id', 'data'],

        components: {
            FHashtags: Hashtags,
            FAccounts: Accounts,
            FIndividualPosts: IndividualPosts,
            FBlockedWords: BlockedWords,
            FBlockedUsers: BlockedUsers,
            FLanguages: Languages
        }
    }
</script>

<style scoped lang="scss">
</style>
