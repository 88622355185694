<template>
    <div>
        <v-card-title class="headline">
            Accounts
        </v-card-title>

        <v-card-text>
            <v-progress-circular
                v-if="loading === 'loading'"
                indeterminate
                color="primary"
            ></v-progress-circular>
            <v-simple-table v-if="loading === 'loaded'">
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-center">ACCOUNT</th>
                            <th class="text-center">TWITTER</th>
                            <th class="text-center">INSTAGRAM</th>
                            <th class="text-center">FACEBOOK</th>
                            <th class="text-center">PULLED FROM</th>
                            <th class="text-center">ACTIONS</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="tempAccount in tempAccounts" :key="tempAccount.account">
                            <td class="text-center">
                                {{ tempAccount.account }}
                            </td>
                            <td class="text-center">
                                <v-icon
                                    :color="tempAccount.type !== 'twitter' ? 'grey darken-3': 'blue'"
                                >fab fa-twitter-square</v-icon>
                            </td>
                            <td class="text-center">
                                <v-icon
                                    :color="tempAccount.type !== 'instagram' ? 'grey darken-3': 'orange darken-3'"
                                >fab fa-instagram</v-icon>
                            </td>
                            <td class="text-center">
                                <v-icon
                                    :color="tempAccount.type !== 'facebook' ? 'grey darken-3': 'blue darken-4'"
                                >fab fa-facebook-square</v-icon>
                            </td>
                            <td class="text-center">
                                {{ tempAccount.start_at }}
                            </td>
                            <td class="text-center">
                                <v-btn
                                    @click="destroy(tempAccount.id)"
                                    icon="icon"
                                ><v-icon class="text-info">fa-trash-alt</v-icon></v-btn>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>

            <v-form ref="form" v-model="formValid">
                <v-row align="center" justify="space-between">
                    <v-col>
                        <v-text-field
                            label="Account"
                            v-model="account.account"
                            :rules="[rules.required]"
                        ></v-text-field>
                    </v-col>
                    <v-col>
                        <v-radio-group v-model="account.feeds_type_id">
                            <v-radio
                                label="Twitter"
                                :value="getValue('twitter')"
                            ></v-radio>
                        </v-radio-group>
                    </v-col>
                    <v-col>
                        <v-menu
                            v-model="menu.date"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="menu.date_formatted"
                                    label="Pulled from"
                                    hint="MM/DD/YYYY format"
                                    persistent-hint
                                    prepend-icon="fa-calendar-alt"
                                    @blur="account.start_at = dateService.parseDate(menu.date_formatted)"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="account.start_at"
                                no-title
                                @input="menu.date = false"
                            ></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col>
                        <v-btn
                            color="primary"
                            @click="add"
                            :loading="isSaving"
                        >Add</v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
    </div>
</template>

<script>
    import _ from 'lodash';
    import DateService from '@/services/date.service';

    export default {

        name: 'Accounts',

        props: ['id'],

        computed: {

            feedTypes() {

                return this.$store.getters['socialmosaic/feedTypes'];
            },

            accounts() {

                return this.$store.getters['socialmosaic/accounts'];
            },

            loading() {

                return this.$store.getters['socialmosaic/accountsStatus'];
            }
        },

        data: () => ({
            dateService: DateService,
            formValid: true,
            menu: {
                date : false,
                date_formatted: DateService.formatDate(DateService.addDays(new Date(), 15).toISOString().substr(0, 10)),
            },
            account: {
                account: '',
                feeds_type_id: 1,
                start_at: new Date().toISOString().substr(0, 10)
            },
            rules: {
                required: value => !!value || 'Required',
                counter: value => value.length >= 5 || 'Min 5 characters'
            },
            tempAccounts: [],
            isSaving: false
        }),

        updated() {
            this.tempAccounts = _.cloneDeep(this.accounts);
        },

        methods: {

            getValue(type) {
                const find = _.find(this.feedTypes, {type});

                return !_.isUndefined(find) ? find.id : null;
            },

            destroy(accountId) {
                this.$store.dispatch('socialmosaic/deleteAccount', {
                    socialMosaicId: this.id,
                    accountId
                }).then(() => {
                    this.$store.dispatch('socialmosaic/getAccounts', this.id).then(() => {
                        this.tempAccounts = _.cloneDeep(this.accounts);
                    }).catch(() => {});
                }).catch(() => {});
            },

            add() {
                this.$refs.form.validate();

                if (this.formValid) {
                    this.isSaving = true;
                    const account = _.cloneDeep(this.account);
                    account.start_at += ' 00:00:00';

                    this.$store.dispatch('socialmosaic/newAccount', {
                        socialMosaicId: this.id,
                        account
                    }).then(() => {
                        this.$store.dispatch('socialmosaic/getAccounts', this.id).then(() => {
                            this.tempAccounts = _.cloneDeep(this.accounts);
                            this.isSaving = false;
                        }).catch(() => {});
                    }).catch(() => {});
                }
            }
        }
    }
</script>
